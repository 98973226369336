<template>
  <div class="UserInfoCard">
    <Collapse :title="$locale['info']" icon="user">
      <table class="UserInfoCardTable" w100>
        <tr v-html="`<td>${$locale['full_name']}</td><td><a class='full_name'>${user.name}</a></td>`"></tr>
        <tr v-html="`<td>${$locale['country']}</td><td>${user.country}</td>`"></tr>
        <tr
          v-html="`<td>${$locale['phone']}</td><td><a href='tel:+${user.calling_code}${user.phone}'>(${user.calling_code}) ${user.phone}</a></td>`"
        ></tr>
        <tr v-html="`<td>${$locale['user_name']}</td><td>@${user.user}</td>`"></tr>
        <tr v-html="`<td>${$locale['email']}</td><td>${user.email}</td>`"></tr>
        <tr v-html="`<td>${$locale['how_know_us']}</td><td>${howKnowUs}</td>`"></tr>
        <tr v-html="`<td>${$locale['comment']}</td><td>${comment}</td>`"></tr>
        <tr v-html="`<td>Instagram</td><td><a href='${user.instagram}' target='_blank' class='url'>${user.instagram}</a></td>`"></tr>
      </table>
    </Collapse>
  </div>
</template>

<script>
export default {
  props: ["user"],
  computed: {
    howKnowUs: function() {
      return (this.user.creatorApply && this.user.creatorApply.howKnowUs) || this.$locale["no_comment"];
    },
    comment: function() {
      return (this.user.creatorApply && this.user.creatorApply.applyComments) || this.$locale["no_comment"];
    },
  },
};
</script>

<style lang="scss">
.UserInfoCard {
  position: relative;
  .collapse-header {
    padding: $mpadding;
    &:hover {
      background-color: $button_contrast;
    }
  }
  .collapse.opened {
    .collapse-header {
      border-bottom: 1px solid $alto;
      background-color: $button_contrast;
      color: $primary_color;
      .iconic {
        color: $primary_color;
      }
    }
  }
  .UserInfoCardTable {
    margin: $mpadding;
  }
  .UserInfoCardTable tr td {
    padding: $mpadding/2 0;
  }
  .UserInfoCardTable tr td:not(:first-child) {
    padding-left: $mpadding;
  }
  .full_name {
    font-family: $third_font_bold;
  }

  .url {
    word-break: break-all;
  }
}
</style>
